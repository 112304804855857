import "./Body.css";
import { useState } from "react";
import {
  Card,
  Typography,
  Fade,
  CardContent,
  Container,
  Box,
  TextField,
  Button,
} from "@mui/material";

import React from "react";
import QRCode from "react-qr-code";

function Body() {
  const [url, setUrl] = useState("");
  const [showQR, setShow] = useState(false);

  const onImageDownload = () => {
    const svg = document.getElementById("QRCode");
    const svgData = new XMLSerializer().serializeToString(svg);
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    const img = new Image();
    img.onload = () => {
      canvas.width = img.width;
      canvas.height = img.height;
      ctx.drawImage(img, 0, 0);
      const pngFile = canvas.toDataURL("image/png");
      const downloadLink = document.createElement("a");
      downloadLink.download = "QRCode";
      downloadLink.href = `${pngFile}`;
      downloadLink.click();
    };
    img.src = `data:image/svg+xml;base64,${btoa(svgData)}`;
  };

  const Content = () => {
    if (showQR && url) {
      return  <Box>
        <QRCode
        id="QRCode"
        value={url}
        size={256}
        style={{ height: "auto", maxWidth: "100%", width: "100%" }}
        viewBox={`0 0 256 256`}
      />
      <Box style={{marginTop:'1em', display:"flex", justifyContent:'space-between', alignItems: 'center'}}>
      <Button
          variant="outlined"
          color="secondary"
          size="medium"
          disableElevation
          style={{fontWeight: 'bold', width: '110px'}}
          onClick={() => setShow(false)}
        >
          Back
        </Button>
      <Button
          variant="contained"
          color="primary"
          size="medium"
          disableElevation
          style={{fontWeight: 'bold', width: '110px'}}
          onClick={() => onImageDownload()}
        >
          Download
        </Button>
      </Box>
      </Box>;
    }

    return (
      <Box className="box-content">
        <TextField
          label="URL to generate QR"
          variant="outlined"
          value={url}
          size="small"
          color="primary"
          style={{ fontWeight: 'bold' }}
          onChange={(ev) => setUrl(ev.target.value)}
        />
        <Button
          variant="contained"
          color="primary"
          size="medium"
          disableElevation
          style={{fontWeight: 'bold', width: '100%'}}
          onClick={() => setShow(true)}
        >
          Generate
        </Button>
      </Box>
    );
  };

  return (
    <Container className="body">
      <Fade in={true} appear={true} unmountOnExit>
        <Card className="card">
          <CardContent>
            <Content></Content>
          </CardContent>
        </Card>
      </Fade>
    </Container>
  );
}

export default Body;
